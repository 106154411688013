<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Tagesabschluss</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <u2b-message type="info">
            Bitte gib den aktuellen Bestand an Bargeld an, der sich in <b>{{cashRegister?.title}}</b> befindet.
        </u2b-message>

        <ng-container *ngIf="formGroup">
            <form [formGroup]="formGroup" class="mt-24">

                <div class="mb-16">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Aktueller SOLL Kassenbestand</mat-label>
                        <input type="number" step="0.01" matInput data-test="FI-Aktueller SOLL Kassenbestand"
                               formControlName="targetCash">
                        <mat-error>{{formGroup.get('targetCash').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Aktueller IST Kassenbestand</mat-label>
                        <input type="number" step="0.01" matInput data-test="FI-Aktueller IST Kassenbestand"
                               formControlName="actualCash">
                        <mat-error>{{formGroup.get('actualCash').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <ng-container *ngIf="differenceSum !== 0">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>Grund für Differenz</mat-label>
                            <textarea matInput data-test="FTGrund für Differenz"
                                      formControlName="differenceReason"
                                      rows="3"
                                      autocomplete="new-password"></textarea>
                            <mat-hint>Betrag der Differenz: <b>{{differenceSum | bcmDynamicCurrency}}</b></mat-hint>
                            <mat-error>{{formGroup.get('differenceReason').errors | firstErrorMessage}}</mat-error>
                        </mat-form-field>
                    </ng-container>

                    <mat-divider class="special-divider"></mat-divider>

                    <div>
                        <mat-checkbox formControlName="cashAddEnabled">
                            Möchtest du Geld in die Kasse einlegen?
                        </mat-checkbox>

                        <ng-container *ngIf="formGroup.get('cashAddEnabled').value">
                            <mat-form-field appearance="outline" class="mt-16" style="width: 100%">
                                <mat-label>Geld in Kasse einlegen</mat-label>
                                <input type="number" step="0.01" matInput data-test="FI-Betrag, der eingelegt wird"
                                       formControlName="cashAdd">
                                <mat-hint>Kassenbestand nach Änderung:
                                    <b>{{(formGroup.get('actualCash').value + formGroup.get('cashAdd').value - formGroup.get('cashLift')?.value) | bcmDynamicCurrency}}</b>
                                </mat-hint>
                                <mat-error>{{formGroup.get('cashAdd').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <mat-divider class="special-divider"></mat-divider>

                    <div>
                        <mat-checkbox formControlName="cashLiftEnabled">
                            Möchtest du Geld aus der Kasse abschöpfen?
                        </mat-checkbox>

                        <ng-container *ngIf="formGroup.get('cashLiftEnabled').value">
                            <mat-form-field appearance="outline" class="mt-16" style="width: 100%">
                                <mat-label>Geld aus Kasse abschöpfen</mat-label>
                                <input type="number" step="0.01" matInput data-test="FI-Betrag, der abgeschöpft wird"
                                       formControlName="cashLift">
                                <mat-hint>Kassenbestand nach Änderung:
                                    <b>{{(formGroup.get('actualCash').value + formGroup.get('cashAdd')?.value - formGroup.get('cashLift').value) | bcmDynamicCurrency}}</b>
                                </mat-hint>
                                <mat-error>{{formGroup.get('cashLift').errors | firstErrorMessage}}</mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <mat-divider class="special-divider"></mat-divider>

                </div>

            </form>
        </ng-container>

        <ng-container
                *ngIf="differenceSum === 0 || formGroup.get('differenceReason').valid; else showDifferenceError">
            <u2b-message type="success">
                Nach Abschluss der Kasse wird automatisch ein Z-Bericht generiert.<br> Dieser kann in der
                Detailansicht der
                Kasse eingesehen werden.
            </u2b-message>
        </ng-container>
        <ng-template #showDifferenceError>
            <u2b-message type="error">
                Der IST Kassenbestand entspricht nicht dem SOLL Kassenbestand.<br>
                Bitte nenne den Grund für die Differenz von <b>{{differenceSum | bcmDynamicCurrency}}</b>
            </u2b-message>
        </ng-template>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>
        <button data-test="B-Kasse schließen (Tagesabschluss)" mat-flat-button
                color="accent"
                (click)="closeCashRegister()"
                class="save-button mat-accent"
                [disabled]="!formGroup.valid || isLoading || !init"
                aria-label="Kasse schließen (Tagesabschluss)">
            <mat-icon>point_of_sale</mat-icon>
            <span>Kasse schließen (Tagesabschluss)</span>
            <ng-container *ngIf="isLoading">
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-container>
        </button>
    </div>

</div>
