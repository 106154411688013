import { Injectable } from '@angular/core';
import {
    adminOnlyNavigationItemsBcm,
    BcmNavigationItem,
    editableNavigationBcmTemplate,
    navigationElements,
    userNavigationItemsBcm
} from '../../navigation/editable-navigation-bcm';
import { environment } from '@env/environment';
import { BcmService } from '@modules/bcm/bcm.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import {
    getAppInviteNavItem,
    getHeaderNavItem,
    getServiceRequestNavItem,
    getVersionNavItem
} from '../../navigation/core-navigation-bcm';
import { ListItem, ListItemDto, ListItemType } from '../../navigation/navigation.shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReleaseNotesDialogComponent } from '@modules/bcm/@shared/components/dialogs/release-notes/release-notes-dialog.component';
import { UserService } from '@core/services/user.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { MailTemplateApiService } from '@bcmApiServices/mail-template.api-service';
import { MailTemplate } from '@shared/models/mail-template';

@Injectable({providedIn: 'root'})
export class BcmNavigationService {

    private _navigation: BcmNavigationItem[];

    private navigationItems: BcmNavigationItem[];

    private readonly _allNavigationItems: BcmNavigationItem[];

    public get allNavigationItems(): BcmNavigationItem[] {
        return this._allNavigationItems;
    }

    private static get navigationClone(): BcmNavigationItem[] {
        return editableNavigationBcmTemplate();
    }

    private inviteTemplate: MailTemplate | undefined;

    constructor(private _bcmTenantService: BcmTenantService,
                private mailTemplateApiService: MailTemplateApiService,
                private _userService: UserService,
                private _bcmService: BcmService,
                private _router: Router,
                private _matDialog: MatDialog,
                private _activatedRoute: ActivatedRoute) {
        this._allNavigationItems = Object.keys(navigationElements)
            .map(key => ({...navigationElements[key], children: []}));

        this._bcmService.selectedTenant$.subscribe(tenant => {
            if (tenant?.id) {
                this.mailTemplateApiService.getOneByTitle('app_invite').subscribe(data => {
                    this.inviteTemplate = data || undefined;
                });
            } else {
                this.inviteTemplate = undefined;
            }
        });
    }

    public navigate(commands: string | any[], extras?: NavigationExtras): Promise<any> {
        return this._router
            .navigate([
                this._bcmService.baseUrl,
                ...(typeof commands === 'string' ? commands.split('/').filter(c => !!c) : commands)
            ], extras);
    }

    public navigateRelative(commands: string | any[]): Promise<any> {
        return this._router
            .navigate([
                this._bcmService.baseUrl,
                ...(typeof commands === 'string' ? commands.split('/').filter(c => !!c) : commands)
            ], {relativeTo: this._activatedRoute});
    }

    public setCustomNavigation(items: ListItemDto[]): void {
        this._applyVersionChanges(items);
        const navigationItems = this._userSettingsToNavigation(items);
        this.setNavigation(navigationItems);
    }

    public getCustomNavigation(items: ListItem[]): ListItemDto[] {
        return this._navigationToUserSettings(items);
    }

    public setNavigation(givenNavigationItems: BcmNavigationItem[]): void {
        const navigation = givenNavigationItems || BcmNavigationService.navigationClone;

        this.navigationItems = this._getNavigationItems(navigation);

        const additionalNavigationItems: BcmNavigationItem[] = [];

        if (this._userService.hasAllPermissionsOf(BcmUserPermission.FULL_GRANT)) {
            additionalNavigationItems.push(...this._prepareTenantNavigationItems(adminOnlyNavigationItemsBcm));
        }

        additionalNavigationItems.push(...this._prepareTenantNavigationItems(userNavigationItemsBcm));

        this._navigation = [
            this._getHeader(),
            ...this.navigationItems,
            ...additionalNavigationItems,
            this._getFooter()
        ];
    }

    public getNavigation(editorMode = false): BcmNavigationItem[] {
        return editorMode
            ? this.navigationItems
            : this._navigation;
    }

    private _getNavigationItems(bcmNavigationItems: BcmNavigationItem[], tenant = this._bcmService.tenant): BcmNavigationItem[] {

        const navigationItems: BcmNavigationItem[] = [];

        for (let i = 0, lng = bcmNavigationItems.length; i < lng; i++) {

            const hasChildren = bcmNavigationItems[i].hasOwnProperty('children') && !!bcmNavigationItems[i].children.length;

            // todo: Entscheidung vom 15.10.2020: Alle Navigationselemente werden immer angezeigt.
            // todo: Entscheidung vom 23.07.2021: Alle Navigationselemente werden NICHT angezeigt, wenn der TENANT das Modul nicht besitzt.
            const permission = bcmNavigationItems[i].tenantPermission;

            if (permission && !this._bcmTenantService.checkPermission(permission)) {
                continue;
            }

            if (hasChildren) {
                bcmNavigationItems[i].children = this._getNavigationItems(bcmNavigationItems[i].children, tenant);
            }

            if (bcmNavigationItems[i].hasOwnProperty('url')
                && typeof bcmNavigationItems[i].url === 'string') {
                if (!bcmNavigationItems[i].url.startsWith(`bcm/${tenant.id}/`)) {
                    bcmNavigationItems[i].url = `bcm/${tenant.id}/${bcmNavigationItems[i].url}`;
                }
            } else if (!bcmNavigationItems[i].function && !bcmNavigationItems[i].children || !bcmNavigationItems[i].children.length) {
                continue;
            }

            navigationItems.push({ ...bcmNavigationItems[i]});
        }

        return navigationItems;
    }

    private _userSettingsToNavigation(items: ListItemDto[]): ListItem[] {
        const navigationItems: ListItem[] = [];
        let newItem: ListItem;

        if (items?.length) {
            for (const item of items) {

                const original = this.allNavigationItems.find(i => i.id === item.id);

                newItem = {
                    ...original,
                    ...item,
                    children: []
                };

                if (item.children?.length) {
                    newItem.children = this._userSettingsToNavigation(item.children);
                }

                navigationItems.push(newItem);
            }

            return navigationItems;
        }

        return BcmNavigationService.navigationClone;
    }

    private _navigationToUserSettings(items: ListItem[]): ListItemDto[] {
        const navigationDtoItems: ListItemDto[] = [];
        let newItem: ListItemDto;

        if (items?.length) {
            for (const item of items) {
                newItem = {
                    id: item.id,
                    type: item.type,
                    title: item.title,
                    children: this._navigationToUserSettings(item.children)
                };

                navigationDtoItems.push(newItem);
            }

            return navigationDtoItems;
        }

        return [];
    }

    private _getHeader(): BcmNavigationItem {
        return getHeaderNavItem(this._bcmService.tenant.name ?? 'Yachthafenverwaltung');
    }

    private _getFooter(): BcmNavigationItem {
        const version = `${this._bcmService.tenant.bcm_version} - ${environment.appVersion} (build: ${environment.appBuildNumber || '-'})`;

        return {
            id: 'others',
            title: 'Sonstiges',
            type: ListItemType.Group,
            children: [
                getServiceRequestNavItem(),
                this.inviteTemplate
                    ? getAppInviteNavItem(() => this.navigate(['messages', 'mails', 'template', this.inviteTemplate.id]))
                    : undefined,
                getVersionNavItem(version, () => this._matDialog.open(ReleaseNotesDialogComponent))
            ]
        };
    }

    private _applyVersionChanges(items: ListItemDto[]): ListItemDto[] {
        return (items || []).map(item => {
            if (item.children?.length) {
                item.children = this._applyVersionChanges(item.children);
            }
            if (item.title === 'Produktkategorien') {
                item.title = 'Warengruppen';
            }
            if (item.title === 'Winterlagerplätze') {
                item.title = 'Lagerplätze';
            }
            if (item.title === 'Trockenlager') {
                item.title = 'Lagerplätze';
            }
            if (item.title === 'Nachrichten') {
                item.title = 'E-Mailnachrichten';
            }
            return item;
        });
    }

    private _prepareTenantNavigationItems(navigationItems: BcmNavigationItem[]): BcmNavigationItem[] {
        const tenant = this._bcmService.tenant;
        return (navigationItems || [])
            .map(item => {
                if (item.hasOwnProperty('url')
                    && typeof item.url === 'string') {
                    if (/^bcm\/\d+/.test(item.url)) { // coming from another tenant
                        item.url = item.url.replace(/^bcm\/\d+/, `bcm/${tenant.id}`);
                    } else if (!item.url.startsWith(`bcm/`)) { // first time
                        item.url = `bcm/${tenant.id}/${item.url}`;
                    }
                    return item;
                } else if (!item.function && !item.children || !item.children.length) {
                    return undefined;
                }
            })
            .filter(item => item !== undefined);
    }
}
