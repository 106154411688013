<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <ng-container *ngIf="showBackButtonInToolbar">
            <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                <u2b-button-browser-back class="ml-16"></u2b-button-browser-back>
            </div>
        </ng-container>

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button data-test="B-Menu" mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <a (click)="leaveBcm()" class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/up2boat.svg">
                </a>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <ng-container *ngIf="newVersionAvailable">
                <div class="toolbar-separator"></div>

                <button data-test="B-Neue Version" mat-flat-button color="primary" class="version-button"
                        style="border-radius: 0" (click)="versionCheckService.openUpdateDialog()">
                    <span fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="s-24" fxHide.xs>new_releases</mat-icon>
                        <span class="ml-8" fxHide fxShow.gt-sm>Neue Version verfügbar!</span>
                    </span>
                </button>
            </ng-container>

            <div class="toolbar-separator"></div>

            <ng-container *ngIf="selectedTenant != null">
                <!--                    <notifications-panel></notifications-panel>-->

                <!--                    <div class="toolbar-separator"></div>-->

                <fuse-search-bar (inputChange)="search($event)" (hasFocusChange)="hasFocusChange($event)"
                                 (collapseChange)="collapseChange()" (click)="$event.stopPropagation()"
                                 [searching]="searching"></fuse-search-bar>

                <div class="toolbar-separator"></div>
            </ng-container>

            <ng-container *ngIf="userService.user as user; else notLoggedIn">
                <button data-test="B-User Menu" mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                    <span fxLayout="row" fxLayoutAlign="center center">
                        <u2b-secured-image class="avatar mr-0 mr-sm-16" [file]="user.image" [placeholder]="user.salutation === salutation.Male ?
                                               'assets/images/placeholder/male.jpg' :
                                               'assets/images/placeholder/female.jpg'"></u2b-secured-image>
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{user.firstName + ' ' + user.lastName}}</span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </span>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <ng-container *ngIf="selectedTenant; else backToStart">
                        <button mat-menu-item class="" (click)="switchTenant()">
                            <mat-icon>autorenew</mat-icon>
                            <span>Hafen wechseln</span>
                        </button>
                    </ng-container>

                    <ng-template #backToStart>
                        <button mat-menu-item class="" (click)="goToStart()">
                            <mat-icon>home</mat-icon>
                            <span>Zur Startseite</span>
                        </button>
                    </ng-template>

                    <a mat-menu-item href="https://www.up2boat.com/support" target="_blank"
                       style="text-decoration: none">
                        <mat-icon>live_help</mat-icon>
                        <span>Service-Anfrage</span>
                    </a>

                    <button mat-menu-item class="" (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Abmelden</span>
                    </button>

                </mat-menu>
            </ng-container>
            <ng-template #notLoggedIn>
                <button data-test="B-Anmelden" mat-button class="user-button" (click)="authService.doLogin()">
                    Jetzt anmelden!
                </button>
            </ng-template>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button data-test="B-Menu" mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar"
                    (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

    <ng-container *ngIf="selectedTenant != null && showSearchFlyOut">

        <div class="global-search-result" (click)="$event.stopPropagation()">

            <ng-container *ngIf="searching; else searchDone">
                <p class="m-0">Suche nach <strong>{{searchTerm}}</strong>...</p>
            </ng-container>

            <ng-container *ngIf="!searchResult && searchTerm?.length < 3">
                <u2b-message type="info" customClass="my-0">
                    <p>
                        Der Suchbegriff muss mindestens <strong>3</strong> Zeichen lang sein.
                    </p>
                </u2b-message>
            </ng-container>

            <ng-template #searchDone>

                <ng-container *ngIf="searchResult">
                    <ng-container *ngIf="searchResult.numberOfResults === 0">
                        <u2b-message type="warning" customClass="mb-0">
                            <header>Keine Ergebnisse</header>
                            Zu dem Suchbegriff <strong>{{searchTerm}}</strong> konnten wir keine Ergebnisse finden.
                        </u2b-message>
                    </ng-container>

                    <ng-container *ngIf="searchResult.numberOfResults">
                        <div>
                            <ng-container *ngIf="searchResult.persons.length">
                                <header>
                                    <mat-icon class="mr-8">person</mat-icon>
                                    <div>
                                        <strong>Personen</strong> gefunden:
                                    </div>
                                </header>

                                <ng-container *ngFor="let person of searchResult.persons">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <div>
                                            <bcm-person-link [person]="person"
                                                             (clicked)="closeSearch()"></bcm-person-link>
                                            <div>
                                                {{person.fullAddress}}
                                                <ng-container *ngIf="person.mail">
                                                    • <a href="mailto:{{person.mail}}">{{person.mail}}</a>
                                                </ng-container>
                                                <ng-container *ngIf="person.phone">
                                                    • <a href="tel:{{person.phone}}">{{person.phone}}</a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.companies.length">
                                <header>
                                    <mat-icon class="mr-8">location_city</mat-icon>
                                    <div>
                                        <strong>Organisationen</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let company of searchResult.companies">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <div>
                                            <bcm-company-link [company]="company"
                                                              (clicked)="closeSearch()"></bcm-company-link>
                                            <div>
                                                {{company.fullAddress}}
                                                <ng-container *ngIf="company.mail">
                                                    • <a href="mailto:{{company.mail}}">{{company.mail}}</a>
                                                </ng-container>
                                                <ng-container *ngIf="company.phone">
                                                    • <a href="tel:{{company.phone}}">{{company.phone}}</a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.boats.length">
                                <header>
                                    <mat-icon class="mr-8">directions_boat</mat-icon>
                                    <div>
                                        <strong>{{'boats' | translate}}</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let boat of searchResult.boats">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <div>
                                            <bcm-boat-link [boat]="boat" (clicked)="closeSearch()"></bcm-boat-link>
                                            <ng-container *ngIf="boat.owner || boat.ownerCompany">
                                                <div>
                                                    {{boat.owner?.fullNameBackward || boat.ownerCompany.fullName}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.berths.length">
                                <header>
                                    <mat-icon class="mr-8">directions_boat</mat-icon>
                                    <div>
                                        <strong>Liegeplätze</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let berth of searchResult.berths">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <bcm-berth-link [berth]="berth" (clicked)="closeSearch()"></bcm-berth-link>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.winterStorageAssignments.length">
                                <header>
                                    <mat-icon svgIcon="winter-storage"></mat-icon>
                                    <div>
                                        <strong>Lagerplätze</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container
                                        *ngFor="let winterStorageAssignment of searchResult.winterStorageAssignments">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <div>
                                            <bcm-winter-storage-link
                                                    [winterStorage]="winterStorageAssignment.winterStorage"
                                                    (clicked)="closeSearch()"></bcm-winter-storage-link>
                                            <div>
                                                <ng-container
                                                        *ngIf="winterStorageAssignment.customAsset; else boat">
                                                    Asset: {{winterStorageAssignment.customAsset}}
                                                </ng-container>
                                                <ng-template #boat>
                                                    {{'boat' | translate}}:
                                                    <bcm-boat-link [boat]="winterStorageAssignment.boat"
                                                                   (clicked)="closeSearch()"></bcm-boat-link>
                                                </ng-template>
                                                <ng-container *ngIf="winterStorageAssignment.from">
                                                    &bull; {{winterStorageAssignment.from | dfnsFormat : 'dd.MM.yyyy'}}
                                                    <ng-container *ngIf="winterStorageAssignment.to">
                                                        - {{winterStorageAssignment.to | dfnsFormat : 'dd.MM.yyyy'}}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="winterStorageAssignment.position">
                                                    &bull; {{winterStorageAssignment.position}}
                                                </ng-container>
                                                <ng-container *ngIf="winterStorageAssignment.helperTools">
                                                    &bull; {{winterStorageAssignment.helperTools}}
                                                </ng-container>
                                            </div>
                                        </div>

                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.electricMeters.length">
                                <header>
                                    <mat-icon class="mr-8">exposure</mat-icon>
                                    <div>
                                        <strong>Stromzähler</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let electricMeter of searchResult.electricMeters">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <bcm-electric-meter-link [electricMeter]="electricMeter"
                                                                 (clicked)="closeSearch()"></bcm-electric-meter-link>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.keys.length">
                                <header>
                                    <mat-icon class="mr-8">vpn_key</mat-icon>
                                    <div>
                                        <strong>Schlüssel</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let key of searchResult.keys">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <bcm-key-link [key]="key" (clicked)="closeSearch()"></bcm-key-link>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="searchResult.invoices.length">
                                <header>
                                    <mat-icon class="mr-8">insert_drive_file</mat-icon>
                                    <div>
                                        <strong>Belege</strong> gefunden:
                                    </div>
                                </header>
                                <ng-container *ngFor="let invoice of searchResult.invoices">
                                    <div>
                                        <mat-icon class="mr-8">arrow_right</mat-icon>
                                        <bcm-invoice-link [invoice]="invoice"
                                                          (clicked)="closeSearch()"></bcm-invoice-link>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </div>
                    </ng-container>
                </ng-container>
            </ng-template>
        </div>
    </ng-container>
</mat-toolbar>
