import { Handleizer } from '@shared/functions/handleize';
import { ICategory } from '@shared/models/category';
import { IUnit } from '@shared/models/unit';
import { PayableOption } from '@shared/models/payable-option';
import { ITaxRate } from '@shared/models/tax-rate';
import { BcmTag } from '@shared/models/bcm-tag';
import { BcmProductDynamicPriceMap, BcmProductDynamicPriceMapDto } from '@shared/models/bcm-price-rule-map';
import { TenantRelation } from '@shared/models/tenant-relation';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export interface IProduct {
    id: number;
    name: string;
    account: string;
    itemNumber: string;
    slug: string;
    description: string;
    category: ICategory;
    unit: IUnit;
    payableOption: PayableOption;
    tags: Array<{ id: number, name: string }>;
    price: string; // coming as string from back-end, need to check why
    taxRate: ITaxRate;
    quantity?: number;
    available: boolean;
    hasDynamicPrice: boolean;
    priceRuleMap?: BcmProductDynamicPriceMapDto;
    sortIndex?: number;
    ignoreFreeOfChargePosition?: boolean;
    costCenters?: BcmCostCenterDto[];
}

export class Product {
    id: number;
    name: string;
    account: string;
    itemNumber: string;
    slug: string;
    description: string;
    category: ICategory;
    unit: IUnit;
    payableOption: PayableOption;
    tags: BcmTag[];
    price: number;
    taxRate: ITaxRate;
    quantity: number;
    available: boolean;
    hasDynamicPrice: boolean;
    priceRuleMap?: BcmProductDynamicPriceMap;
    dockSiteTag: boolean;
    sortIndex?: number;
    ignoreFreeOfChargePosition?: boolean;

    fromTenantRelation?: boolean;
    tenantRelation?: TenantRelation;
    tenantRelationAssignment?: TenantRelationAssignment;
    fromBerth?: boolean;
    berthAssignmentIndex?: number;

    costCenters: BcmCostCenter[];

    /**
     * Constructor
     *
     * @param product
     */
    constructor(product: IProduct = {} as IProduct) {
        this.id = product.id;
        this.name = product.name || '';
        this.account = product.account || '';
        this.itemNumber = product.itemNumber || '';
        this.slug = product.slug || Handleizer.generateSlug(this.name);
        this.description = product.description || '';
        this.category = product.category;
        this.unit = product.unit;
        this.payableOption = product.payableOption;
        this.tags = (product.tags || []).map(tag => new BcmTag(tag));
        this.price = parseFloat(product.price) || 0;
        this.taxRate = product.taxRate;
        this.quantity = product.quantity ?? 0;
        this.available = product.available || true;
        this.hasDynamicPrice = product.hasDynamicPrice;
        this.priceRuleMap = product.priceRuleMap ? new BcmProductDynamicPriceMap(product.priceRuleMap) : null;
        this.sortIndex = product.sortIndex || null;
        this.ignoreFreeOfChargePosition = product.ignoreFreeOfChargePosition || false;
        this.dockSiteTag = this.hasDockSiteTag(product);
        this.costCenters = (product.costCenters || []).map(costCenter => new BcmCostCenter(costCenter));
    }

    public hasDockSiteTag(product): boolean {
        if (!product.tags) {
            return false;
        }

        return product.tags.find(tag => tag.name.toLowerCase().includes('docksite'));
    }

    public get totalPrice(): number {
        return this.price * this.quantity;
    }
}
