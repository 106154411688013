import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BerthsFacade } from '@bcmServices/berths/berths-facade';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ElectricMeterCabinetsFacade } from '@bcmServices/electric-meters/electric-meter-cabinets-facade';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BerthsMapFilterService } from '@bcmServices/berths/berths-map-filter.service';
import { addDays, subDays } from 'date-fns';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { BcmMapEditorSettings, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { fromEvent, Observable, withLatestFrom } from 'rxjs';
import { MapFavourite } from '@shared/models/map-interfaces';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { SharedLayerService } from '@modules/bcm/berths/berths-map/_shared/shared-layer.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { v4 as uuid } from 'uuid';
import { LabelType, Options } from 'ngx-slider-v2';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

export enum DateRangeType {
    Today = 'today',
    Tomorrow = 'tomorrow',
    RestOfWeek = 'restWeek',
    RestOfMonth = 'restMonth',
    Custom = 'custom',
}

@UntilDestroy()
@Component({
    selector: 'berth-map-action-bar',
    templateUrl: './berth-map-action-bar.component.html',
    styleUrls: ['./berth-map-action-bar.component.scss'],
})
export class BerthMapActionBarComponent implements AfterViewInit {
    @ViewChild('dateRangeMenuTrigger') dateRangeMenuTrigger!: MatMenuTrigger;
    @ViewChild('moreMenuFavouritesTrigger') moreMenuFavouritesTrigger!: MatMenuTrigger;

    DateRangeType = DateRangeType;

    dateRangeStartValue: Date;

    dateRangeEndValue: Date;

    FabOptions = {
        buttons: []
    };

    sliderDisabled: boolean;

    sliderVisible = false;

    sliderOptions: Options = {
        floor: 0,
        ceil: 1440, // 24h * 60m
        step: 5,
        noSwitching: true,
        translate: (value: number): string => {

            const timeString = this.berthsFilterService.getTimeStringFromSliderValue(value);

            return `${timeString} Uhr`;
        },
        animate: false,
        hideLimitLabels: true,
    };

    $favourites: Observable<MapFavourite[]> = this.settingsFacade
        .settings$
        .pipe(map(settings => settings[BcmSettingsSectionName.MapEditor]?.favourites || []));

    constructor(
        public berthsFilterService: BerthsMapFilterService,
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _berthFacade: BerthsFacade,
        private appNotificationService: AppNotificationService,
        private _electricMeterCabinetsFacade: ElectricMeterCabinetsFacade,
        private bcmNavigationService: BcmNavigationService,
        private settingsFacade: BcmSettingsFacade,
        private confirmDialogService: ConfirmDialogService,
        private informationDialogService: InformationDialogService,
        private sharedLayerService: SharedLayerService,
    ) {
        berthsFilterService.timeRange.valueChanges
            .pipe(
                untilDestroyed(this),
                filter(() => !this.sliderDisabled),
                distinctUntilChanged(),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(([start, end]) => {

                const startDate = new Date(berthsFilterService.dateRangeForm.get('start').value);
                const endDate = new Date(berthsFilterService.dateRangeForm.get('end').value);

                const startHours = startDate.getHours();
                const startMinutes = startDate.getMinutes();

                const endHours = endDate.getHours();
                const endMinutes = endDate.getMinutes();

                const startVal = (startHours * 60) + startMinutes;
                let endVal = (endHours * 60) + endMinutes;

                if (endVal >= 1438) {
                    endVal = 1440;
                }

                if (startVal !== start || endVal !== end) {

                    const startHour = berthsFilterService.getHourStringFromSliderValue(start);
                    let startMinute = berthsFilterService.getMinuteStringFromSliderValue(start);

                    if (startMinute === 60) {
                        startMinute = 59;
                    }

                    let endHour = berthsFilterService.getHourStringFromSliderValue(end);
                    let endMinute = berthsFilterService.getMinuteStringFromSliderValue(end);

                    if (endMinute === 0) {
                        endHour -= 1;
                        endMinute = 59;
                    } else if (endMinute === 60) {
                        endMinute -= 1;
                    }

                    startDate.setHours(startHour, startMinute, 0);
                    endDate.setHours(endHour, endMinute, 59);

                    berthsFilterService.dateRangeForm.patchValue({start: startDate, end: endDate});

                    return;
                }

                berthsFilterService.updateBerthView(true);

            });

        berthsFilterService.dateRangeForm.get('end').valueChanges
            .pipe(
                untilDestroyed(this),
                withLatestFrom(berthsFilterService.dateRangeForm.get('start').valueChanges),
                map(value => ({start: new Date(value[1]), end: new Date(value[0])})),
                distinctUntilChanged()
            )
            .subscribe(range => {
                if (range.start && range.end) {

                    if (range.start.getDay() !== range.end.getDay()) {
                        this.berthsFilterService.timeRange.disable({emitEvent: false});
                        this.sliderDisabled = true;
                    } else {
                        this.berthsFilterService.timeRange.enable({emitEvent: false});
                        this.sliderDisabled = false;
                    }

                    const startHours = range.start.getHours();
                    const startMinutes = range.start.getMinutes();

                    const endHours = range.end.getHours();
                    const endMinutes = range.end.getMinutes();

                    const startVal = (startHours * 60) + startMinutes;
                    let endVal = (endHours * 60) + endMinutes;

                    if (endVal >= 1438) {
                        endVal = 1440;
                    }

                    const sliderValue = berthsFilterService.timeRange.value;

                    if (sliderValue[0] !== startVal || sliderValue[1] !== endVal) {
                        this.berthsFilterService.timeRange.setValue([startVal, endVal], {emitEvent: false});
                        return;
                    }

                }
            });

    }

    ngAfterViewInit(): void {
        this.berthsFilterService
            .dateRangeForm
            .valueChanges
            .subscribe(({start, end}) => {
                this.dateRangeStartValue = start;
                this.dateRangeEndValue = end;
            });

        this.FabOptions.buttons = [
            {
                icon: 'compare_arrows',
                method: () => this.openTable()
            },
            {
                icon: 'star',
                method: () => this.moreMenuFavouritesTrigger?.openMenu()
            },

            {
                icon: 'more_vert',
                method: () => this.dateRangeMenuTrigger?.openMenu()
            },
            {
                icon: 'tune',
                method: () => this.berthsFilterService.toggleAdvancedFilters()
            }
        ];
    }

    openEditor(): void {
        this.bcmNavigationService.navigate(['berths', 'map', 'editor']);
    }

    openTable(): void {
        this.bcmNavigationService.navigate(['berths', 'list']);
    }

    jumpDays(days: number): void {

        const {start: currStart, end: currEnd} = this.berthsFilterService.dateRangeForm.value;

        let start: Date;
        let end: Date;

        if (days < 0) {
            start = subDays(currStart, Math.abs(days));
            end = subDays(currEnd, Math.abs(days));
        }

        if (days > 0) {
            start = addDays(currStart, days);
            end = addDays(currEnd, days);
        }

        this.berthsFilterService.dateRangeForm.patchValue({
            start, end
        });
        this.berthsFilterService.updateBerthView();
    }

    newFavourite(): void {
        this.confirmDialogService
            .setTitle('Neue Ansicht speichern')
            .setBody(
                'Es wird die aktuelle Ansicht der Karte gespeichert. Diese kannst Du jetzt noch anpassen. ' +
                'Schiebe dafür dieses Fenster einfach beiseite und verändere die Kartenansicht.'
            )
            .setYesButton({text: 'Speichern'})
            .setNoButton({text: 'Abbrechen'})
            .appendInputToBody({
                label: 'Name der Ansicht',
                name: 'name',
                defaultValue: '',
                validators: [U2bValidators.required('Bitte Name der Ansicht angeben')],
                type: 'text'
            })
            .setDialogConfig({hasBackdrop: false})
            .openAndReturnResult<{ name: string }>()
            .subscribe(result => {
                if (result == null) {
                    return;
                }

                const mapCenter = this.sharedLayerService.map.getCenter();
                const mapZoom = this.sharedLayerService.mapZoom;

                this.$favourites
                    .pipe(take(1))
                    .subscribe(favourites => {
                        this.settingsFacade
                            .updateSectionItem<BcmMapEditorSettings, MapFavourite[]>(
                                BcmSettingsSectionName.MapEditor,
                                'favourites',
                                [
                                    ...favourites,
                                    {
                                        id: uuid(),
                                        name: result.name,
                                        center: mapCenter,
                                        zoom: mapZoom,
                                    } as MapFavourite
                                ]
                            );
                    });
            });
    }

    changeMapView(favourite: MapFavourite): void {
        fromEvent(this.sharedLayerService.map, 'moveend')
            .pipe(take(1))
            .subscribe(() => {
                this.sharedLayerService.map.setZoom(favourite.zoom);
            });

        this.sharedLayerService.map.panTo(favourite.center);
    }

    deleteFavourite(favouriteToRemove: MapFavourite, event: MouseEvent): void {
        event.stopPropagation();
        this.confirmDialogService
            .useWarnTheme()
            .setBody('Möchtest Du den Favoriten wirklich löschen?')
            .openAndReturnResult()
            .subscribe(result => {
                if (result) {
                    this.$favourites
                        .pipe(take(1))
                        .subscribe(favourites => {
                            this.settingsFacade
                                .updateSectionItem<BcmMapEditorSettings, MapFavourite[]>(
                                    BcmSettingsSectionName.MapEditor,
                                    'favourites',
                                    favourites.filter(favourite => favourite.id !== favouriteToRemove.id)
                                );
                        });
                }
            });
    }

    showFavouritesHelp(): void {
        this.informationDialogService
            .setTitle('Favorisierte Ansichten')
            .setBody(
                '<p>Mit fav. Ansichten hast Du die Möglichkeit, den aktuellen Kartenausschnitt zu speichern. ' +
                'Das System merkt sich den Ausschnitt und speichert diesen ab. Berücksichtigt wird hierbei das ' +
                'Zoom-Level und der Mittelpunkt der Karte.</p>' +
                '<p>Gespeicherte fav. Ansichten werden dann oben rechts unter "Fav. Ansichten" angezeigt und können mit ' +
                'einem Klick geladen werden. Hierbei springt die KArte auf die zuvor festgelegte Ansicht.</p>' +
                '<p>Eine zuvor gespeicherte Ansicht kann über das rotee Mülleiner-Symbol wieder gelöscht werden.</p>' +
                '<p>Deine Ansichten werden auch den anderen Benutzern angezeigt, die auf den frafischen Liegeplan ' +
                'zugreifen dürfen.<p>'
            )
            .open();
    }
}
