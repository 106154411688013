<div [formGroup]="tenantRelationFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline && !slimmedView">
        <div fxFlex=100>
            <h2>{{headline}}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="topList?.length || bottomList?.length">
        <div fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                    <mat-label>Beziehung</mat-label>
                    <input type="text" matInput data-test="FI-Nach Beziehung suchen..."
                           formControlName="tenantRelation"
                           [matAutocomplete]="tenantRelationSelector">
                    <button matSuffix
                            mat-icon-button
                            type="button"
                            color="warn"
                            matTooltip="Ausgewählte Beziehung entfernen"
                            [disabled]="!tenantRelation?.id || parentFormGroup.disabled"
                            (click)="onClickRemoveTenantRelation()">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <mat-error [hidden]="slimmedView">{{tenantRelationFormGroup.get('tenantRelation').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <mat-autocomplete #tenantRelationSelector="matAutocomplete" [displayWith]="displayTenantRelationWith">
                    <ng-container *ngIf="personOrCompanyName">
                        <ng-container *ngIf="filteredTopList$ | async as topList">
                            <mat-optgroup [label]="'Beziehungen von ' + personOrCompanyName">
                                <ng-container *ngFor="let tenantRelation of topList">
                                    <mat-option [value]="tenantRelation">
                                        {{tenantRelation.name}}
                                    </mat-option>
                                    <ng-container *ngIf="!topList?.length">
                                        {{personOrCompanyName}} besitzt keine Beziehung
                                    </ng-container>
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                        <mat-optgroup [label]="personOrCompanyName ? 'Weitere Beziehungen' : 'Alle Beziehungen'">
                            <ng-container *ngFor="let tenantRelation of bottomList">
                                <mat-option [value]="tenantRelation" [disabled]="disableOtherRelations">
                                    {{tenantRelation.name}}
                                </mat-option>
                                <ng-container *ngIf="!bottomList?.length">
                                    Keine Beziehungen gefunden
                                </ng-container>
                            </ng-container>
                        </mat-optgroup>
                    </ng-container>
                </mat-autocomplete>
            </div>
        </div>
    </ng-container>

    <!--    <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">-->
    <!--        <mat-label>Beziehung</mat-label>-->
    <!--        <mat-select data-test="FS-Beziehung"-->
    <!--                    formControlName="tenantRelation"-->
    <!--                    [attr.placeholder]="loading ? 'Lade Beziehung' : ''"-->
    <!--                    [compareWith]="compareTenantRelations">-->
    <!--            <mat-option *ngFor="let tenantRelationType of tenantRelations"-->
    <!--                        [value]="tenantRelationType">{{tenantRelationType.name}}</mat-option>-->
    <!--        </mat-select>-->
    <!--        <mat-error>{{tenantRelationFormGroup.get('tenantRelation').errors | firstErrorMessage}}</mat-error>-->
    <!--    </mat-form-field>-->

    <ng-container *ngIf="tenantRelation?.products?.length && !slimmedView">
        <div fxFlex="100" class="mb-16">
            <table class="simple sm">
                <thead>
                <tr>
                    <th>Produkt</th>
                    <th style="width: 80px;" class="text-center">Anzahl</th>
                    <th class="text-right">
                        Einzelpreis<br>
                        <small>({{tenantRelation.payableOption?.name}})</small>
                    </th>
                    <th class="text-right">Summe</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let product of (tenantRelation?.products || []); let i = index">
                    <tr>
                        <td>{{product.name}}</td>
                        <td style="width: 80px;" class="text-center">
                            {{product.quantity}}
                        </td>
                        <td class="text-right">
                            {{product.price | bcmDynamicCurrency: '1.2-2'}}
                        </td>
                        <td class="text-right">
                            {{(product.quantity || 0) * (product.price || 0) | bcmDynamicCurrency: '1.2-2'}}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="3" class="no-border"></td>
                    <td class="text-right padding sum">
                        {{selectedTenantRelationProductPriceTotal | bcmDynamicCurrency: '1.2-2'}}
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </ng-container>

</div>
