import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(protected authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.token
            .pipe(
                mergeMap((token) => {
                    if (token
                        && !req.url.includes('https://auth.up2boat.com')
                        && !req.url.includes(environment.endpoints.pegelOnline)) {
                        const headers: HttpHeaders = req.headers;
                        const headersWithAuthorization: HttpHeaders = headers.append('Authorization', 'bearer ' + token);
                        const requestWithAuthorizationHeader = req.clone({headers: headersWithAuthorization});
                        return next.handle(requestWithAuthorizationHeader);
                    }

                    return next.handle(req);
                })
            );
    }
}
