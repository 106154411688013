import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TenantRelationsApiService } from '@modules/bcm/@shared/services';
import { TenantRelation } from '@shared/models/tenant-relation';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Boat } from '@shared/models/boat';

@Component({
    selector: 'form-widget-tenant-relation',
    templateUrl: './form-widget-tenant-relation.component.html'
})
export class FormWidgetTenantRelationComponent implements OnInit, OnDestroy {

    @Input()
    headline = 'Beziehung';

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    set prefilledTenantRelationId(value: number) {
        this._prefilledTenantRelationId = value;
    }

    get prefilledTenantRelationId(): number {
        return this._prefilledTenantRelationId;
    }

    private _prefilledTenantRelationId: number;

    @Input()
    set selectTenantRelation(value: TenantRelation) {
        if (value?.id || value?.id !== this._selectTenantRelation?.id) {
            this._selectTenantRelation = value;
        }
    }

    get selectTenantRelation(): TenantRelation {
        return this._selectTenantRelation;
    }

    private _selectTenantRelation: TenantRelation;

    @Input()
    givenTenantRelations: TenantRelation[];

    @Input()
    personOrCompanyName: string;

    @Input()
    tenantRelationRequired = true;

    @Input()
    slimmedView = false;

    @Input()
    givenBerth: Berth;

    @Input()
    givenBoat: Boat;

    @Input()
    disableOtherRelations = false;

    tenantRelationFormGroup: UntypedFormGroup;

    tenantRelations: TenantRelation[];

    tenantRelation: TenantRelation;

    selectedTenantRelationProductPriceTotal: number;

    loading: boolean;

    topList: TenantRelation[] = [];

    bottomList: TenantRelation[] = [];

    filteredTopList$: Observable<TenantRelation[]>;

    filteredBottomList$: Observable<TenantRelation[]>;

    private _unsubscribeAll = new Subject();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _tenantRelationsApiService: TenantRelationsApiService,
                private _productService: ProductService) {
    }

    ngOnInit(): void {
        this.tenantRelation = this.selectTenantRelation;

        this._createForm();
        this._loadTenantRelations(this.givenTenantRelations);

        setTimeout(() => {
            this.parentFormGroup.addControl('tenantRelationForm', this.tenantRelationFormGroup);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
        this.parentFormGroup.removeControl('tenantRelationForm');
    }

    private _createForm(): void {
        this.tenantRelationFormGroup = this._formBuilder.group({
            tenantRelation: [this.selectTenantRelation, this.tenantRelationRequired ? [U2bValidators.required('Bitte Beziehung angeben')] : []],
        });

        this.tenantRelationFormGroup
            .get('tenantRelation')
            .valueChanges
            .subscribe((value: TenantRelation) => {
                this.tenantRelation = value;

                const boat = this.givenBoat || this.parentFormGroup.get('boatForm')?.value?.boat;

                const parameters = {boat, berth: this.givenBerth, tenantRelation: this.tenantRelation};

                const dynPrices$ = [];

                for (const product of (value?.products || []).filter(p => p.hasDynamicPrice)) {
                    dynPrices$.push(
                        this._productService.evaluatePriceRule(product, parameters, product.quantity)
                            .pipe(map(dynamicPrice => ({
                                ...product,
                                dynamicPrice
                            })))
                    );
                }


                if (dynPrices$.length > 0) {
                    forkJoin(dynPrices$)
                        .subscribe(result => {
                            this.tenantRelation.products = [
                                ...result,
                                ...(value?.products || []).filter(p => !p.hasDynamicPrice)
                            ];

                            this.selectedTenantRelationProductPriceTotal = (this.tenantRelation?.products || [])
                                .reduce((accumulator: number, product) => {
                                    return accumulator + ((product?.price || 0) * (product?.quantity || 0));
                                }, 0);
                        });
                } else {
                    this.selectedTenantRelationProductPriceTotal = (this.tenantRelation?.products || [])
                        .reduce((accumulator: number, product) => {
                            return accumulator + ((product?.price || 0) * (product?.quantity || 0));
                        }, 0);
                }

            });
    }

    private _loadTenantRelations(givenTenantRelations: TenantRelation[] = []): void {
        this.topList = [];
        this.bottomList = [];

        this.loading = true;

        this._tenantRelationsApiService
            .getAll()
            .subscribe((tenantRelations) => {
                for (const tenantRelation of tenantRelations) {
                    if (this.personOrCompanyName && givenTenantRelations.find(givenTenantRelation => givenTenantRelation.id === tenantRelation.id)) {
                        this.topList.push(tenantRelation);
                    } else {
                        this.bottomList.push(tenantRelation);
                    }
                }
                if (this.prefilledTenantRelationId && tenantRelations?.length) {
                    this.tenantRelationFormGroup.patchValue({
                        tenantRelation: tenantRelations.find(tr => tr.id === this.prefilledTenantRelationId) || null
                    });
                }
            })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.tenantRelationFormGroup.get('tenantRelation').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterTenantRelations(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.tenantRelationFormGroup.get('tenantRelation').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterTenantRelations(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterTenantRelations(filter: string, tenantRelations: TenantRelation[]): TenantRelation[] {
        return tenantRelations.filter(tenantRelation => tenantRelation.name.toLowerCase().includes(filter.toLowerCase()));
    }

    compareTenantRelations(tenantRelation1: TenantRelation, tenantRelation2: TenantRelation): boolean {
        return tenantRelation1 && tenantRelation2 && tenantRelation1.id === tenantRelation2.id;
    }

    public displayTenantRelationWith(tenantRelation: any): string {
        return tenantRelation?.name || '';
    }

    onClickRemoveTenantRelation(): void {
        this.tenantRelationFormGroup.get('tenantRelation').setValue(null);
        this.tenantRelationFormGroup.markAsDirty();
    }
}
