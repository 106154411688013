import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { TenantRelation } from '@shared/models/tenant-relation';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { TenantRelationsApiService } from '@modules/bcm/@shared/services';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { hours, minutes } from '@shared/constants/date';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { Season } from '@shared/models/seasons';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import { PayableOptionId } from '@bcmApiServices/product-payable-option.api-service';
import { isValidDate } from '@core/functions/is-valid-date';
import { addDays, addHours, addMonths, addWeeks, addYears, endOfMonth, subMinutes, } from 'date-fns';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { Product } from '@shared/models/product';
import { PayableOption } from '@shared/models/payable-option';

@Component({
    selector: 'dialog-tenant-relation',
    templateUrl: './get-tenant-relation-data-dialog.component.html',
    styles: [':host { background-color: red; }  .mat-form-field {display: block !important;}']
})
export class GetTenantRelationDataDialogComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject();

    @Input()
    tenantRelationAssignment: TenantRelationAssignment;

    protected readonly BcmTenantPermission = BcmTenantPermission;

    tenantRelations: TenantRelation[];

    seasons: Season[];

    seasonFormControl = new FormControl<Season>(null);

    @Input()
    parentFormGroup: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    loading: boolean;

    hours = hours;

    minutes = minutes;

    PayableOptionId = PayableOptionId;

    productsCostCenterFormControls: FormControl[] = [];

    products: Product[] = [];

    tenantRelation?: TenantRelation;

    payableOption?: PayableOption;

    constructor(
        public dialogRef: MatDialogRef<GetTenantRelationDataDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            tenantRelationAssignment: TenantRelationAssignment,
        },
        private _formBuilder: UntypedFormBuilder,
        private _tenantRelationsApiService: TenantRelationsApiService,
        private _seasonApiService: SeasonApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');

        this.tenantRelationAssignment = this.data?.tenantRelationAssignment;

        this.formGroup = this._formBuilder.group(
            {
                fromDate: [
                    this.tenantRelationAssignment?.fromDate,
                    [
                        U2bValidators.required('Bitte angeben, zu welchem Datum das Abo starten soll.'),
                    ]
                ],
                toDate: [this.tenantRelationAssignment?.toDate],
                periodFromDate: [
                    this.tenantRelationAssignment?.periodFromDate,
                    this.tenantRelationAssignment?.periodFromDate ? [] :
                        [U2bValidators.required('Bitte angeben, zu welchem Datum das Abo für die Beziehung starten soll.')]
                ],
                periodToDate: [this.tenantRelationAssignment?.periodToDate],
                vestingPeriodFrom: [
                    this.tenantRelationAssignment?.vestingPeriodFrom,
                    this.tenantRelationAssignment?.vestingPeriodFrom ? [] :
                        [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
                ],
                vestingPeriodUntil: [
                    {
                        value: this.tenantRelationAssignment?.vestingPeriodUntil || null,
                        disabled: this.tenantRelationAssignment?.lastDayOfMonth
                    },
                    [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
                ],
                lastDayOfMonth: [this.tenantRelationAssignment?.lastDayOfMonth || false],
            },
            {
                validators: [
                    U2bDateValidators.dateBeforeOtherDate('fromDate', 'toDate', 'Start', 'Ende'),
                    U2bDateValidators.dateBeforeOtherDate('periodFromDate', 'periodToDate', 'Abo Start', 'Abo Ende'),
                    U2bDateValidators.dateBeforeOtherDate('vestingPeriodFrom', 'vestingPeriodUntil', 'Leistungszeitraum Start', 'Leistungszeitraum Ende'),
                ]
            }
        );

        if (this.parentFormGroup) {
            this.parentFormGroup.addControl('tenantRelationAssignmentForm', this.formGroup);
        }

        const form = this.formGroup;
        const tenantRelationField = form.get('tenantRelationForm')?.get('tenantRelation');
        const vestingPeriodFromField = form.get('vestingPeriodFrom');
        const vestingPeriodUntilField = form.get('vestingPeriodUntil');
        const lastDayOfMonthField = form.get('lastDayOfMonth');

        form.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(value => value?.tenantRelationForm?.tenantRelation)
            )
            .subscribe(tenantRelation => {
                if (!tenantRelation) {
                    this.tenantRelation = undefined;
                    this.products = [];
                    this.productsCostCenterFormControls = [];
                    this.payableOption = undefined;
                    return;
                }

                this.tenantRelation = tenantRelation;
                this.payableOption = tenantRelation?.payableOption;

                const controlNames = [
                    'periodFromDate',
                    'periodToDate',
                    'vestingPeriodFrom',
                    'vestingPeriodUntil',
                ];

                if (tenantRelation?.products?.length) {
                    this.products = tenantRelation.products;
                    this.productsCostCenterFormControls = (tenantRelation?.products || []).map(product => {
                        const costCenter = product.costCenters?.length === 1
                            ? product.costCenters[0]
                            : undefined;
                        return new FormControl(costCenter);
                    });

                    enableControlsByName(this.formGroup, controlNames, false, {emitEvent: false});
                } else {
                    this.products = [];
                    this.productsCostCenterFormControls = [];

                    disableControlsByName(this.formGroup, controlNames, {emitEvent: false});
                }
            });

        vestingPeriodFromField
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                if (isValidDate(value) && tenantRelationField?.value?.payableOption && vestingPeriodUntilField.value == null) {
                    let newDate: Date;
                    switch (tenantRelationField?.value?.payableOption.id) {
                        case PayableOptionId.Yearly:
                            newDate = addYears(value, 1);
                            break;
                        case PayableOptionId.HalfYearly:
                            newDate = addMonths(value, 6);
                            break;
                        case PayableOptionId.QuarterYearly:
                            newDate = addMonths(value, 3);
                            break;
                        case PayableOptionId.Monthly:
                            newDate = addMonths(value, 1);
                            break;
                        case PayableOptionId.Weekly:
                            newDate = addWeeks(value, 1);
                            break;
                        case PayableOptionId.Daily:
                            newDate = addDays(value, 1);
                            break;
                        case PayableOptionId.Hourly:
                            newDate = addHours(value, 1);
                            break;
                    }
                    vestingPeriodUntilField.patchValue(subMinutes(newDate, 1));
                }
            });

        lastDayOfMonthField
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                if (value) {
                    form.get('vestingPeriodUntil').disable();
                    form.get('vestingPeriodUntil').patchValue(endOfMonth(form.get('vestingPeriodUntil').value || form.get('vestingPeriodFrom').value), {emitEvent: false});
                } else {
                    form.get('vestingPeriodUntil').enable();
                }
            });
    }

    ngOnInit(): void {
        this.loading = true;
        this._tenantRelationsApiService
            .getAll()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tenantRelations) => {
                this.tenantRelations = tenantRelations;
                this.loading = false;
            });
        this._seasonApiService
            .getAllSeasons()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((seasons) => {
                this.seasons = seasons;
            });

        this.seasonFormControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(season => {
                this.formGroup.patchValue({
                    vestingPeriodFrom: season?.startDate || null,
                    vestingPeriodUntil: season?.endDate || null
                });
            });
    }

    ngOnDestroy(): void {
        if (this.parentFormGroup) {
            this.parentFormGroup.removeControl('tenantRelationAssignmentForm');
        }
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    compareSeasons(season1: Season, season2: Season): boolean {
        return season1?.id === season2?.id;
    }

    save(): void {

        if (!this.formGroup.get('tenantRelationForm')?.get('tenantRelation').value?.products?.length) {
            this.formGroup.get('periodFromDate').disable();
            this.formGroup.get('vestingPeriodFrom').disable();
            this.formGroup.get('vestingPeriodUntil').disable();
        } else {
            this.formGroup.get('periodFromDate').enable();
            this.formGroup.get('vestingPeriodFrom').enable();
            this.formGroup.get('vestingPeriodUntil').enable();
        }

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const formValueRaw = this.formGroup.getRawValue();
        const costCenters = this.productsCostCenterFormControls.map(control => control.value);

        this.dialogRef.close({
            ...formValueRaw,
            tenantRelation: formValueRaw.tenantRelationForm.tenantRelation,
            costCenters,
        });
    }
}
