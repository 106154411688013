import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppLoggingService } from '@core/services/app-logging.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { environment } from '@env/environment';
import { AppErrorService } from '@core/services/app-error.service';
import { TseManagementApiService } from '@bcmApiServices/tse/tse-management.api-service';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private _errorService = new AppErrorService();

    constructor(
        private appLoggingService: AppLoggingService,
        private appNotificationService: AppNotificationService,
        private tseManagementApiService: TseManagementApiService,
        private authService: AuthService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const suppressErrors = request.headers.get('suppress-errors') === 'true';

        return next
            .handle(request)
            .pipe(
                // retry(1),
                debounceTime(100), // front-end errors are often triggered very often
                catchError((error: HttpErrorResponse) => {

                    if (suppressErrors) {
                        return throwError(() => error);
                    }

                    let errorMessage = 'Es ist ein unerwartetes Problem aufgetreten. Sollte diese Meldung erneut erscheinen, wende Dich bitte an unseren Support.';
                    let skipNotification = false;

                    if (error.status === 401) {
                        // Unauthorized
                        errorMessage = 'Du bist nicht angemeldet. Leite weiter zu Login...';

                        setTimeout(async () => {
                            this.authService.signOut();
                        }, 3000);
                    } else if (error.status === 403) {
                        // Forbidden
                        errorMessage = 'Dir fehlen die nötigen Berechtigungen für diese Aktion.';
                    } else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        const message = this._errorService.getClientMessage(error as HttpErrorResponse);
                        const stack = this._errorService.getClientStack(error);

                        if (environment.production) {
                            this.appLoggingService.logError(message, error, stack);
                            skipNotification = true;
                        } else {
                            errorMessage = `[${error.status}]: ${error.error.message}`;
                        }
                    } else {
                        // server-side error
                        if (error.error?.name === 'BcmError') {
                            errorMessage = error.error.message;
                        } else if (request.url.includes('/tse/')) { // temporary solution - move to BE soon...
                            if (error.status === 424 || error.status === 502) {

                                const clientId = request.params.get('clientId');

                                if (clientId?.length > 0) {
                                    return this.tseManagementApiService
                                        .connectCloud(clientId)
                                        .pipe(switchMap(() => next.handle(request)));
                                }

                            }
                            skipNotification = true;
                        } else {
                            if (environment.production) {
                                if (error.status < 500) {
                                    skipNotification = true;
                                }
                            } else {
                                errorMessage = `Error Status: ${error.status}<br>Message: ${error.message}<br>Error: ${error.error?.message}`;
                            }
                        }
                    }

                    if (error.error instanceof Blob) {
                        error.error.text().then(err => {
                            const blobError = JSON.parse(err);
                            if (blobError.name === 'BcmError') {
                                errorMessage = blobError.message;
                            } else {
                                if (environment.production) {
                                    if (blobError.status < 500) {
                                        skipNotification = true;
                                    }
                                } else {
                                    errorMessage = `Error Status: ${blobError.status}<br>Message: ${blobError.message}`;
                                }
                            }
                            this.appNotificationService.showError(errorMessage);
                        });
                    } else if (errorMessage && !skipNotification) {
                        this.appNotificationService.showError(errorMessage);
                    }

                    return throwError(error);
                })
            );
    }
}
