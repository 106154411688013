import { BcmPaymentType, IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { padLeft } from '@shared/functions';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { BookingItemType } from '@modules/bcm/@shared/enums/berth-item-type';
import { BcmOrder } from '@shared/models/bcm-order';
import { Boat } from '@shared/models/boat';
import { Berth } from '@shared/models/berth';
import { Contract } from '@shared/models/contract';

export interface IBcmBookingStatus {
    id: string;
    name: string;
}

export class BcmBookingStatus {
    id: string;
    name: string;

    constructor(data: IBcmBookingStatus) {
        this.id = data.id;
        this.name = data.name;
    }
}

export interface IBcmBookingTraveler {
    id: number;
    person?: IPerson;
    name: string;
    email: string;
    phone: string;
    birthDate: Date;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
}

export class BcmBookingTraveler {
    id: number;
    person?: Person;
    name: string;
    email?: string;
    phone?: string;
    birthDate?: Date;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;

    constructor(dto: IBcmBookingTraveler) {
        this.id = dto.id;
        this.person = dto.person ? new Person(dto.person) : null;
        this.name = dto.name;
        this.email = dto.email;
        this.phone = dto.phone;
        this.birthDate = dto.birthDate;
        this.insertedOn = dto.insertedOn;
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = dto.lastUpdateOn;
        this.lastUpdateBy = dto.lastUpdateBy;
    }
}

export interface AddOrUpdateBcmBookingDto {
    booking: Partial<BcmBooking>;
    travelers?: IBcmBookingTraveler[];
    berthAssignments?: Partial<BerthBoatAssignment>[];
    tenantRelationAssignment?: Partial<TenantRelationAssignment>;
    contract?: {
        generateContract?: boolean;
        contractCoOwner?: { [key: string]: string };
        contract: Contract;
        contractFileType: number;
        mail?: string;
        sendViaMail?: boolean;
        generatePdf?: boolean;
        downloadPdf?: boolean;
    };
    invoice?: {
        payByCash?: boolean;
        createInvoice?: boolean;
        dueDateDays?: number;
        paymentType?: BcmPaymentType;
        sendToPrinter?: boolean;
        sendToMail?: boolean;
        mail?: string;
        positions?: InvoicePosition[];
    };
}

// export interface BcmBookingChanges {
//     positions?: {
//         new?: InvoicePosition[];
//         updated?: InvoicePosition[];
//         deleted?: InvoicePosition[];
//     };
//     berthAssignments?: {
//         new?: Partial<BerthBoatAssignment>[];
//         updated?: Partial<BerthBoatAssignment>[];
//         deleted?: Partial<BerthBoatAssignment>[];
//     };
//     tenantRelationAssignment?: {
//         new?: Partial<TenantRelationAssignment>;
//         updated?: Partial<TenantRelationAssignment>;
//         deleted?: Partial<TenantRelationAssignment>;
//     };
// }

export interface IBcmBooking {
    id?: number;

    person: IPerson;
    company: ICompany;
    status?: IBcmBookingStatus;

    travelers?: IBcmBookingTraveler[];

    berthAssignments?: IBerthBoatAssignment[];
    positions?: InvoicePositionDto[];
    invoice?: IInvoice;
    // invoices?: IInvoice[];

    personSubscriptions?: ProductSubscriptionDto[];
    companySubscriptions?: ProductSubscriptionDto[];

    personTenantRelationAssignment: TenantRelationAssignmentDto;
    companyTenantRelationAssignment: TenantRelationAssignmentDto;

    from?: Date;
    to?: Date;

    isReservation: boolean;
    reservedUntil: Date;
    reservationText: string;
    arrivalDone: boolean;
    departureDone: boolean;
    cancelledOn: Date;
    cancellationReason: string;
    note: string;
    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmBooking {

    // values from database
    id: number;

    person: Person;
    company: Company;
    status: BcmBookingStatus;

    travelers?: BcmBookingTraveler[];

    berthAssignments: BerthBoatAssignment[];
    positions: InvoicePosition[];
    invoice: Invoice;
    // invoices: Invoice[];

    personSubscriptions?: ProductSubscription[];
    companySubscriptions?: ProductSubscription[];

    tenantRelationAssignment?: TenantRelationAssignment;

    contract: Contract; // TODO multiple contracts ?

    isReservation: boolean;
    reservedUntil: Date;
    reservationText: string;
    arrivalDone: boolean;
    departureDone: boolean;
    cancelledOn: Date;
    cancellationReason: string;
    note: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    // helper
    number: string; // auto generated booking number by id
    positionsSaldo: number;
    itemType?: BookingItemType;
    openOrders?: BcmOrder[];
    from: Date;
    to: Date;
    boat: Boat;
    arrivalBerth: Berth;
    departureBerth: Berth;
    saldo: number;

    constructor(data: IBcmBooking) {
        this.id = data.id;

        this.person = data.person ? new Person(data.person) : null;
        this.company = data.company ? new Company(data.company) : null;
        this.status = data.status ? new BcmBookingStatus(data.status) : null;

        this.travelers = (data.travelers || []).map(traveler => new BcmBookingTraveler(traveler));

        this.berthAssignments = (data.berthAssignments || []).map(assignment => new BerthBoatAssignment(assignment));
        this.positions = (data.positions || []).map(position => new InvoicePosition(position));

        for (let i = 0; i < this.berthAssignments.length; i++) {
            const berthAssignment = this.berthAssignments[i];
            this.positions.map(position => {
                if (position.bcm_berth_has_assignments_id > 0 && position.bcm_berth_has_assignments_id === berthAssignment.id) {
                    position.berthAssignmentIndex = i;
                }
                return position;
            });
        }

        this.invoice = new Invoice(data.invoice);
        // this.invoices = (data.invoices || []).map(invoice => new Invoice(invoice));

        this.personSubscriptions = (data.personSubscriptions || []).map(s => new ProductSubscription(s));
        this.companySubscriptions = (data.companySubscriptions || []).map(s => new ProductSubscription(s));

        if (data.personTenantRelationAssignment) {
            this.tenantRelationAssignment = new TenantRelationAssignment(data.personTenantRelationAssignment);
        } else if (data.companyTenantRelationAssignment) {
            this.tenantRelationAssignment = new TenantRelationAssignment(data.companyTenantRelationAssignment);
        }

        this.isReservation = data.isReservation;
        this.reservedUntil = tryParseDate(data.reservedUntil);
        this.reservationText = data.reservationText;
        this.arrivalDone = data.arrivalDone;
        this.departureDone = data.departureDone;
        this.cancelledOn = tryParseDate(data.cancelledOn);
        this.cancellationReason = data.cancellationReason;
        this.note = data.note;

        this.insertedOn = tryParseDate(data.insertedOn);
        this.insertedBy = data.insertedBy;
        this.lastUpdateOn = tryParseDate(data.lastUpdateOn);
        this.lastUpdateBy = data.lastUpdateBy;

        // helper
        this.number = padLeft(data.id, '000');

        this.positionsSaldo = this.positions
            .map(p => p.price * p.quantity)
            .reduce((acc, val) => acc + val, 0);

        if (data.from) {
            this.from = tryParseDate(data.from);
        } else {
            this.from = this.berthAssignments.length > 0 ? new Date(Math.min(...this.berthAssignments.map(a => a.from.getTime()))) : new Date();
        }

        if (data.to) {
            this.to = tryParseDate(data.to);
        } else {
            this.to = this.berthAssignments.length > 0 ? new Date(Math.max(...this.berthAssignments.map(a => a.to?.getTime()))) : null;
        }

        this.boat = this.berthAssignments.length > 0 ? this.berthAssignments[0].boat : null;
        this.arrivalBerth = this.berthAssignments.length > 0 ? this.berthAssignments[0].berth : null;

        this.departureBerth = this.berthAssignments.length > 0 ? this.berthAssignments[this.berthAssignments.length - 1].berth : null;

        this.saldo = ((this.person || this.company)?.positions || [])
            .reduce((accumulator: number, position) => {
                return accumulator + position.totalPrice;
            }, 0);
    }

    getMinFrom(): Date {
        return this.berthAssignments.length > 0 ? new Date(Math.min(...this.berthAssignments.map(a => a.from.getTime()))) : new Date();
    }

    getMaxTo(): Date {
        return this.berthAssignments.length > 0 ? new Date(Math.max(...this.berthAssignments.map(a => a.to?.getTime()))) : null;
    }

}
