import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { BcmService } from '@modules/bcm/bcm.service';
import { Tenant } from '@shared/models/tenant';
import { ActivationStart, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { SalutationNew } from '@shared/models/salutation';
import { emptySearchResult, SearchApiService, SearchResult } from '@bcmApiServices/search.api-service';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { FuseSearchBarComponent } from '@fuse/components/search-bar/search-bar.component';
import { VersionCheckService } from '@bcmServices/version-check.service';
import { StateResetService } from '@bcmServices/state-reset.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    selectedTenant: Tenant;
    salutation = SalutationNew;

    showBackButtonInToolbar = false;

    private _unsubscribeAll = new Subject();
    private _searchNext = new Subject<any>();
    private _hasFocus = new Subject<boolean>();

    public searchResult: SearchResult;

    public searching: boolean;

    public searchTerm: string;

    public hasFocus: boolean;

    public showSearchFlyOut: boolean;

    public newVersionAvailable = false;

    @ViewChild(FuseSearchBarComponent)
    searchBarComponent: FuseSearchBarComponent;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        const key = event.code || event.key;

        if (event.altKey && key === 'KeyS' && this.searchBarComponent) {
            this.searchBarComponent.expand();
        }
    }

    @HostListener('document:click', ['$event'])
    handleMouseClickEvent(): void {
        this.showSearchFlyOut = false;
    }

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _bcmService: BcmService,
        private _router: Router,
        public userService: UserService,
        public authService: AuthService,
        public searchApiService: SearchApiService,
        public versionCheckService: VersionCheckService,
    ) {
        this.versionCheckService
            .hasNewVersion$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.newVersionAvailable = true;
            });

        this._bcmService
            .selectedTenant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tenant: Tenant) => this.selectedTenant = tenant);

        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.navigation = navigation;

        this._searchNext
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((search) => {
                    this.searchTerm = search;
                    this.searching = true;
                }),
                switchMap((search) => {
                    if (!search || search?.length < 3) {
                        return of(null);
                    }
                    return this.searchApiService
                        .search(search)
                        .pipe(catchError(() => of(emptySearchResult)));
                }),
                catchError(() => of(emptySearchResult))
            )
            .subscribe((searchResult) => {
                this.searchResult = searchResult;
                this.searching = false;
            });

        this._hasFocus
            .pipe(
                // debounceTime(DEFAULT_DEBOUNCE_TIME / 2),
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged(),
            )
            .subscribe((hasFocus) => setTimeout(() => this.hasFocus = hasFocus, 0));

        StateResetService.onReset.subscribe(() => {
            this.searchResult = undefined;
            this.searchTerm = undefined;
            this.searching = false;
            this.hasFocus = false;
            this.showSearchFlyOut = false;
        });
    }

    ngOnInit(): void {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        this._router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                if (data instanceof ActivationStart) {
                    this.showBackButtonInToolbar = data.snapshot?.data?.showBackButtonInToolbar;
                }
            });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    leaveBcm(): void {
        this._bcmService.reset();
        this._router.navigate(['/bcm']);
    }

    goToStart(): void {
        this._router.navigate(['/']);
    }

    switchTenant(): void {
        this._bcmService.reset();
        this._router.navigate(['/bcm/tenants']).then(() => location.reload());
    }

    logout(): void {
        this.userService.reset();
        this._bcmService.reset();
        this.authService.signOut();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    search(value): void {
        this._searchNext.next(value);
        this.showSearchFlyOut = true;
    }

    hasFocusChange(value): void {
        this._hasFocus.next(value);
        this.showSearchFlyOut = true;
    }

    collapseChange(): void {
        this.showSearchFlyOut = false;
    }

    closeSearch(): void {
        this.searchTerm = '';
        this.searchResult = null;
        this.searchBarComponent.collapse();
        this.showSearchFlyOut = false;
    }

}
