import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { Berth, IBerth } from '@shared/models/berth';
import { Key, KeyRaw } from '@shared/models/key';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import {
    IWinterStorageBoatAssignment,
    WinterStorageBoatAssignment
} from '@shared/models/winter-storage-boat-assignment';
import { map } from 'rxjs/operators';


export interface SearchResultRaw {
    numberOfResults: number;
    persons: IPerson[];
    companies: ICompany[];
    boats: IBoat[];
    berths: IBerth[];
    keys: KeyRaw[];
    invoices: IInvoice[];
    winterStorageAssignments: IWinterStorageBoatAssignment[];
    electricMeters: IElectricMeter[];
}

export interface SearchResult {
    numberOfResults: number;
    persons: Person[];
    companies: Company[];
    boats: Boat[];
    berths: Berth[];
    keys: Key[];
    invoices: Invoice[];
    winterStorageAssignments: WinterStorageBoatAssignment[];
    electricMeters: ElectricMeter[];
}

export const emptySearchResult = {
    numberOfResults: 0,
    persons: [],
    companies: [],
    boats: [],
    berths: [],
    keys: [],
    invoices: [],
    winterStorageAssignments: [],
    electricMeters: [],
};

@Injectable({
    providedIn: 'root'
})
export class SearchApiService extends BcmApi2Service {

    search(search: string): Observable<SearchResult> {
        return super.post<SearchResultRaw>('search', undefined, new HttpParams().set('search', search))
            .pipe(map((result) => {
                return {
                    numberOfResults: result.numberOfResults,
                    persons: (result.persons || []).map(person => new Person(person)),
                    companies: (result.companies || []).map(company => new Company(company)),
                    boats: (result.boats || []).map(boat => new Boat(boat)),
                    berths: (result.berths || []).map(berth => new Berth(berth)),
                    keys: (result.keys || []).map(key => new Key(key)),
                    invoices: (result.invoices || []).map(invoice => new Invoice(invoice)),
                    winterStorageAssignments: (result.winterStorageAssignments || []).map(winterStorageAssignment => new WinterStorageBoatAssignment(winterStorageAssignment)),
                    electricMeters: (result.electricMeters || []).map(electricMeter => new ElectricMeter(electricMeter))
                };
            }));
    }

}
