import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { DataFilterOperator } from '@core/datafilter/filter-operator';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { Tenant } from '@shared/models/tenant';

export enum U2bColumnPipeName {
    Number = 'number',
    Decimal = 'decimal',
    Currency = 'currency',
    Percent = 'percent',
    Date = 'date',
    DateTime = 'datetime',
    DfnsFormat = 'DfnsFormat',
}

export enum U2bColumnDefinitionBrakePoint {
    Desktop = 'd',
    Tablet = 't',
    Mobile = 'm',
}

export enum U2bTableTextAlignments {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}

export const U2bColumnDefinitionBrakePointName: { [key in U2bColumnDefinitionBrakePoint]: string } = {
    [U2bColumnDefinitionBrakePoint.Desktop]: 'Desktop',
    [U2bColumnDefinitionBrakePoint.Tablet]: 'Tablet',
    [U2bColumnDefinitionBrakePoint.Mobile]: 'Mobil',
};

export enum U2bColumnDefinitionExternalComponent {
    TenantRelationColoredName = 'TenantRelationColoredName',
    BerthsStatusIndicator = 'BerthsStatusIndicator',
    BerthsFreeScoreIndicator = 'BerthsStatusIndicator',
    BcmBerthLink = 'BcmBerthLink',
    BcmOwnerLink = 'BcmOwnerLink',
    DocumentHolder = 'DocumentHolder',
    BcmBoatLink = 'BcmBoatLink',
    BerthsAssignments = 'BerthsAssignments',
    ElectricMeter = 'ElectricMeter',
    ElectricMeterAssignments = 'ElectricMeterAssignments',
    FinancialRecordType = 'FinancialRecordType',
    FinancialRecordNumber = 'FinancialRecordNumber',
    FinancialRecordCustomer = 'FinancialRecordCustomer',
    FinancialRecordCancellation = 'FinancialRecordCancellation',
    InvoiceStatus = 'InvoiceStatus',
    MailCreator = 'MailCreator',
    TenantRelations = 'TenantRelations',
    MailRejectReport = 'MailRejectReport',
    MailDeliveredReport = 'MailDeliveredReport',
    ContractTypeWithIcon = 'ContractTypeWithIcon',
    Documents = 'Documents', // ???
}

export enum U2bFilterSelectOptionsRelations {
    TenantRelations = 'tenantRelations',
    Countries = 'countries',
    PayableOptions = 'payableOptions',
    ProductCategories = 'productCategories',
    Units = 'units',
    TaxRates = 'taxRates',
    Products = 'products',
    DocumentCategories = 'documentCategories',
    // BerthStatus = 'berthStatus'
}

export interface U2bColumnDefinitionFilterSettings {
    property?: string; // default: columnDefinition.property
    fieldName?: string; // property, that is used for filtering in front-end
    fieldType?: FilterFieldType; // default: FilterFieldType.Text
    operators?: DataFilterOperator[]; // if not set, default added by fieldType
    compareAttribute?: string | [string, string];
    selectOptions?: any[];
    selectOptionsTranslations?: { [key: string | number | symbol]: string };
    selectOptionsDisplayWith?: string;
    selectOptionsFilterBy?: string[];
    selectOptionsRelation?: U2bFilterSelectOptionsRelations;
    selectedOperationType?: any;
}

export interface U2bColumnDefinitionTooltip {
    header?: string;
    body: string;
}

// todo: change to class and instantiate each column?
export interface U2bColumnDefinition {
    property: string;
    sortProperty?: string;
    name: string;
    infoTooltip?: U2bColumnDefinitionTooltip;
    hideNameInHeader?: boolean;
    useNetGrossPriceHeaderLabel?: boolean;
    disableSort?: true;
    parseItem?: (item: unknown) => any;
    isActive: { [key in U2bColumnDefinitionBrakePoint]: boolean };
    position?: { [key in U2bColumnDefinitionBrakePoint]: number };
    alignment?: { [key in U2bColumnDefinitionBrakePoint]: U2bTableTextAlignments };
    isAvatar?: boolean;
    avatarAttribute?: string;
    pipe?: U2bColumnPipeName;
    externalComponent?: U2bColumnDefinitionExternalComponent;
    externalComponentMetaData?: { [key: string]: any };
    fixedWidth?: string;
    minWidth?: string;
    translationKey?: string;
    filter?: U2bColumnDefinitionFilterSettings;
    excludeIf?: (tenant: Tenant) => boolean; // just add more attributes, if needed. Is there a better way to exclude conditionally?
}

export interface U2bTableData<T = unknown> {
    tableId: BcmUserTableSettingKey;
    columnDefinitions: U2bColumnDefinition[];
    uniqueIdentAttribute?: 'id' | string;
    hasServerSideFiltering?: true;
    activeSortColumn?: string;
    alignment?: U2bTableTextAlignments;
}

export interface U2bTableStyleDefaults {
    rowHeight?: 'xs' | 's' | 'm' | 'l';
    fontSize?: 's' | 'm' | 'l' | 'xl';
    alignment?: U2bTableTextAlignments;
}
