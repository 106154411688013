<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen" type="button" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <mat-tab-group dynamicHeight="true" #tabGroup="matTabGroup">

            <mat-tab label="Freemium" data-test="TH-Freemium">

                <p>Yachthafenmanagement <span class="text-super">Freemium</span> für <span
                    class="mat-title">0,00 €</span> kaufen.</p>

                <form>

                    <mat-vertical-stepper linear #stepper>

                        <ng-template matStepperIcon="edit">
                            <mat-icon>edit</mat-icon>
                        </ng-template>

                        <mat-step [stepControl]="formGroup.get('tenant')">
                            <ng-template matStepLabel>Dein Yachtclub / Bootclub</ng-template>

                            <form-widget-tenant [parentFormGroup]="formGroup"></form-widget-tenant>
                        </mat-step>

                        <mat-step [stepControl]="formGroup.get('user')">
                            <ng-template matStepLabel>Ansprechpartner - Deine Daten</ng-template>

                            <form-widget-user [parentFormGroup]="formGroup"></form-widget-user>
                        </mat-step>

                        <mat-step [stepControl]="formGroup.get('conditionsAndPrivacy')">
                            <ng-template matStepLabel>AGB und Datenschutzerklärung</ng-template>

                            <form-widget-conditions-and-privacy
                                [parentFormGroup]="formGroup"></form-widget-conditions-and-privacy>
                        </mat-step>

                        <mat-step [stepControl]="formGroup.get('avv')">
                            <ng-template matStepLabel>AVV - Auftragsverarbeitungsvertrag</ng-template>

                            <form-widget-avv [parentFormGroup]="formGroup"></form-widget-avv>
                        </mat-step>

                    </mat-vertical-stepper>

                </form>

                <small>* Pflichtfeld</small><br>
                <small>Freemium heißt, dass auf Wunsch Erweiterungen dazugekauft werden können.</small>
            </mat-tab>

            <mat-tab label="Premium" data-test="TH-Premium">
                <h2>Darf es etwas mehr sein?</h2>

                <em>Bald verfügbar...</em>

                <p>Mit <strong>Yachthafenmanagement <span class="text-super">PREMIUM</span></strong> hast Du Zugriff auf
                    alle Premium-Features für monatlich <span class="mat-title">nur 2,00 €</span> pro Liegeplatz.
                </p>

                <p class="pt-32 mat-subheading-2">Unsere Premium-Features:</p>
                <ul>
                    <li>Sammelrechnungen</li>
                    <li>Stromzähler</li>
                    <li>???</li>
                </ul>

                <p class="mat-subheading-2">Viele weitere Module sind in Entwicklung:</p>
                <ul>
                    <li>Grafische Liegeplatzverwaltung</li>
                    <li>Statistiken</li>
                    <li>uvm.</li>
                </ul>
            </mat-tab>

        </mat-tab-group>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" type="button" mat-flat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <ng-template #saving>
            <mat-icon>save</mat-icon>
            <span>Speichert...</span>
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-template>

        <ng-template #notSaving>
            <mat-icon>add_shopping_cart</mat-icon>
            <ng-container *ngIf="tabGroup.selectedIndex === 0">
                Jetzt für 0,00 € kaufen
            </ng-container>
            <ng-container *ngIf="tabGroup.selectedIndex > 0">
                Bald verfügbar...
            </ng-container>
        </ng-template>

        <button data-test="B-Speichern" type="button" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="!formGroup.dirty || tabGroup.selectedIndex !== 0 || isSaving"
                aria-label="Speichern">
            <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
        </button>

    </div>
</div>
